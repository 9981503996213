<template>
    <div>
        <div class="news-head-line">
        </div>
        <div class="container-1" style="margin-bottom:10rem">
            <img class="news-img" src="../../assets/image/news/05-4中图.png"/>
            <div class="news-box">
                <div class="title">“乘云而上——构建高度集成的一体化数字管理平台”研讨会顺利举办</div>
                <div class="date">2022/12/09</div>
                <div class="line"></div>
                <div class="content">2022年11月30日，由畅享网承办的“乘云而上——构建高度集成的一体化数字管理平台”在上海顺利举办。在尊重疫情防控要求的前提下，与
                    会人员一同基于如何利用云服务的弹性、敏捷性、全球化部署等特点，探讨企业上云机遇与方案，助力企业数字化战略落地以及核心业务效能提升。<br/><br/>

                    会议开始，上海比孚信息科技有限公司（以下简称“比孚”）董事长许东日线上作开场致辞。他表示比孚自2013年成立至今，一直以来的目标就是扎根上海，做好
                    口碑；扎根行业，做深服务；扎根技术，做好交付。同时，比孚始终坚持“专业客观，客户至上”的服务理念，为客户提供更具针对性的专业服务以及易于管理、
                    方便落实、快速见效的专业解决方案。⽐孚将技术服务整合为数据服务、SAP服务和技术开发服务三大服务板块，聚焦快消零售、医疗健康以及智能制造三大行
                    业，提供从咨询设计到开发落地运维的完整技术服务。

                    <div class="insert-img">
                       <img src="../../assets/image/news/许总.png"/> 
                       <div class="insert-title">上图：上海比孚信息科技有限公司董事长许东日</div>
                    </div>
                    
                    
                    云上智能，创新未来，基于云计算服务的ERP将成为未来ERP系统发展的重要趋势。上海比孚信息科技有限公司CTO施韬分享了数字时代的企业ERP智慧旅程。作为
                    亚马逊云科技的高级咨询合作伙伴，⽐孚致力于利用大数据和云计算技术为企业数字化转型赋能。为了应对客户的通用性需求，⽐孚积累了一套完整且成熟的实施
                    路径和模板，在降低迁移风险的同时，提高迁移效率并降低迁移成本和复杂度。据介绍，⽐孚已成功交付多个迁移项目，利用短、平、快的敏捷实施方法论，实现
                    最少两周的迁移周期，停机时间低至12⼩时起，迁移后为客户降低32%总拥有成本。

                    <div class="insert-img">
                       <img src="../../assets/image/news/施总.png"/> 
                       <div class="insert-title">上图：上海比孚信息科技有限公司CTO施韬</div>
                    </div>

                    亚马逊云科技合作伙伴经理张显围绕“亚马逊云科技携手比孚赋能企业数字化乘云而上”主题发表演讲。云是未来发展的趋势。纵观整个行业，40%的企业加快了云迁
                    移进程，45%的企业开始实施云迁移或拓展了云迁移的布局。亚马逊云科技作为技术底座支撑着SAP，加速ERP现代化，持续助力企业云端创新。张显表示，亚马逊云
                    科技目前在国内有三个区域，分别是北京、香港和宁夏。每个区域只少有2个可用区，可用区之间地理与网络都是独立设计与运营，实现极低成本的同城异地实时容灾
                    ，所以有且只有在亚马逊云科技,SAP提供RPO=0的灾备选项。

                    <div class="insert-img">
                       <img src="../../assets/image/news/张显.png"/> 
                       <div class="insert-title">上图：亚马逊云科技合作伙伴经理张显</div>
                    </div>
                    研讨会最后，可口可乐IT高级经理王小锋线上跟大家分享了ERP上云实践及心得。可口可乐拥有着一套围绕SAP ERP核心业务系统的完整IT应用系统组合，以支持业务
                    运营及生产需求，并通过系统间接口实现主要系统间的集成与交互。王小锋主要介绍了上云的挑战和机遇、上云规划以及SAP上云方案。他表示，上云后的项目收益主
                    要为：整体性能配置提升20%、系统备份速度提升50%、整体TCO降低30%、运维人力降低30%、故障恢复目标时间减少50%、业务平均响应时间减少30%。

                    <div class="insert-img">
                       <img src="../../assets/image/news/王小锋.png"/> 
                       <div class="insert-title">上图：可口可乐IT高级经理王小锋</div>
                    </div>

                    以科技重构企业，加速数字化战略落地。本次研讨会充分表明云是未来发展的趋势。上云充分体现了企业快速应变的能力，提高系统环境的稳定性、保障数据安全性、降
                    低运营成本、为企业提供一站式云管理解决方案，以此推动企业加快数字化、网络化、智能化转型，实现智慧企业运营。
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import store from '@/plugins/store';
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {
        };
    },

    mounted() {
        setTimeout(()=>{
            this.refreshIndexInfo({data:'5' , news:true});
        },500)
    },

    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
    },
};
</script>

<style lang="less" scoped>
.news-head-line{
    width: 190rem;
    border: 1px solid #F3FAF6;
    margin-top: 11rem;
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.2);
}
.news-img{
    width: 100rem;
    height: 30rem;
    margin-top: 4rem;
}
.news-box{
    width: 100rem;
    // height: 130rem;
    margin: auto;
    margin-top: 6rem; 
    .title{
        height: 2.6rem;
        font-size: 2.6rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #333333;
        line-height: 3.9rem;
        text-align: justify;
    }
    .date{
        height: 1.9rem;
        font-size: 1.6rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #999999;
        line-height: 2.4rem;
        text-align: justify;
        margin-top: 2rem;
    }
    .line{
        width: 100rem;
        height: 1px;
        border: 1px solid #EDEDED;
        margin-top: 1rem;
    }
    .content{
        width: 100.1rem;
        // height: 46.7rem;
        font-size: 1.6rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #4F4F4F;
        line-height: 3rem;
        margin-top: 2.4rem;
        text-align: justify;
        .insert-img{
            margin-top: 2rem;
            margin-bottom: 2rem;
            img{
                width: 65.4rem;
                height: 40rem;
                display: flex;
                margin: auto;
            }
            .insert-title{
                font-size: 1.6rem;
                font-family: 'CN_Regular';
                font-weight: 400;
                color: #999999;
                line-height: 2.4rem;
                text-align: center;
                margin-top: 2rem;
            }
        }
    }
}
</style>